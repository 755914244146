.pricing-section {
    padding: 60px 304px;
    background-color: #282829;

    @media screen and (max-width:1620px) {
        padding: 60px 180px;
    }

    @media screen and (max-width:1360px) {
        padding: 60px 103px;
    }

    @media screen and (max-width:768px) {
        padding: 32px 16px;
    }

    .pricing-header {
        text-align: center;
        color: #FFF;

        h2 {
            font-family: 'Sofia Pro Semi';
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;

            @media screen and (max-width:1055.9px) {
                font-size: 20px;
                line-height: 32px;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            font-style: normal;
        }
    }

    .payment-modes {
        display: none;
        margin-bottom: 24px;
        padding: 0 16px;

        @media screen and (min-width:576px) {
            max-width: 400px;
            margin: 0 auto 24px;
        }

        @media screen and (max-width:1055.9px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            gap: 16px;
        }

        span {
            width: 50%;
            text-align: center;
            padding: 4px 0;
            font: normal normal 400 14px/24px Sofia Pro;
            background-color: #282829;
            color: #FFF;
            position: relative;
            overflow: hidden;
            z-index: 1;
            transition: all 0.5s ease;
            border: 1px solid white;
            border-radius: 48px;


            &::before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                background: #FFF;
                z-index: -1;
                transition: all 0.5s ease;
                border-radius: 32px;
            }

            &:first-of-type {
                padding-right: 8px;

                &::before {
                    left: calc(100% + 8px);
                }

                &.active {
                    &::before {
                        left: 0;
                    }
                }
            }

            &:last-of-type {
                // padding-left: 8px;

                &::before {
                    right: calc(100% + 8px);
                }

                &.active {
                    &::before {
                        right: 0;
                    }
                }
            }

            &.active {
                // background-color: $white;
                color: #282829;
            }
        }
    }


    .paycards-container {
        margin: 24px -16px;

        .slick-slide {
            padding: 0 16px;
        }

        .paycard {
            background-color: white;
            border-radius: 16px;
            text-align: center;

            .paycard-header {
                background-color: #008CFF;
                border-radius: 16px 16px 0 0;
                padding: 16px 32px;

                @media screen and (max-width:1055.9px) {
                    padding: 12px 16px;
                }

                h1 {
                    color: #FFF;
                    font-family: 'Sofia Pro Semi';
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    margin-bottom: 0;
                }
            }

            .paycard-body {
                padding: 32px;

                @media screen and (max-width:1055.9px) {
                    padding: 24px 16px;
                }

                h1 {
                    font-family: 'Sofia Pro Semi';
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 40px;
                    margin-bottom: 8px;

                    @media screen and (max-width:1055.9px) {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }

                h3 {
                    font-family: 'Sofia Pro Semi';
                    font-size: 44px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 64px;

                    @media screen and (max-width:1055.9px) {
                        font-size: 40px;
                        line-height: 112px;

                    }

                }

                div.aligner-card-price {
                    min-height: 64px;
                    display: table;
                    margin: 0 auto;

                    .price-content {
                        font-size: 32px;
                        font-weight: 400;
                        line-height: 40px;
                        text-wrap: wrap;
                        min-width: fit-content;
                        margin: 0 4px;
                        display: table-cell;
                        vertical-align: middle;
                        line-height: 64px;

                        span {

                            &:first-child,
                            &:last-child {
                                font-family: 'Sofia Pro Semi';
                                font-size: 44px;
                                font-style: normal;
                                font-weight: 700;
                                margin: 0;

                                @media screen and (max-width:1260px) {
                                    font-size: 2rem;
                                }
                            }
                        }
                    }

                    @media screen and (max-width:1055.9px) {
                        min-height: 112px;

                        .price-content {
                            line-height: normal;

                            span {

                                &:first-child,
                                &:last-child {
                                    line-height: 40px;
                                    font-size: 32px;
                                }
                            }

                            &.price-content-two {
                                span {
                                    font-size: 24px;

                                    &:first-child,
                                    &:last-child {
                                        font-size: 28px;
                                    }
                                }
                            }
                        }

                    }
                }

                .pricing-divider {
                    margin: 12px 0 24px;
                    border-bottom: 1px solid #282829;
                }

                .aligner-pricing {
                    color: #6E6E73;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }

                .pricing-divider-secondary {
                    border-bottom: 1px solid #E6E6E6;
                    margin: 24px 0;
                }
            }

            &.paycard-two {
                .paycard-header {
                    background-color: #005AE2;
                }
            }
        }
    }

    .pricing-terms {
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        margin-bottom: 4px;
    }

    .payment-method-slider {
        margin: 24px 0 32px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }

    .free-assessment-wrapper {
        text-align: center;

        a {
            background-color: #008CFF;
            border-radius: 48px;
            color: #FFF;
            outline: none;
            padding: 12px 24px;
            text-decoration: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            display: inline-block;

            @media screen and (max-width:1055.9px) {
                padding: 8px 24px;
            }

            @media screen and (max-width:768px) {
                display: block;
            }
        }
    }


}
@import '../globals/colors.scss';

.aligner-animated-section {
    position: relative;
    padding: 104px 60px;
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    background-image: url('/img/updated/banner/aligner-2-desktop.jpg');


    @media only screen and (max-width: 1023.9px) {
        min-height: auto;
        display: block;
        padding: 30px 16px;
        background-image: url('/img/updated/banner/aligner-2-mobile.jpg');

    }

    .iso-logo-container {
        position: absolute;
        bottom: 32px;
        right: 32px;
        width: 142px;
        height: 86px;

        @media only screen and (max-width: 1023.9px) {
            position: static;
            margin: 50px auto 0 auto;
            width: 102px;
            height: 63px;
        }

        img {
            width: 100%;
            height: 100%;
        }

    }

    .animated-container {
        display: flex;
        max-width: 1504px;
        width: 100%;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        min-height: 415px;
        margin: 0 auto;

        @media only screen and (max-width: 1023.9px) {
            min-height: auto;
            display: block;
        }

        .left-col {
            max-width: 458px;
            margin-right: 101px;

            @media only screen and (max-width: 1023.9px) {
                min-height: 283px;
                width: 100%;
                padding: 32px 0;
                margin: 0px auto;
            }

            img {
                width: 100%;
            }
        }

        .right-col {
            color: $white;
            width: calc(100% - 558px);

            @media only screen and (max-width: 1023.9px) {
                width: 100%;
                text-align: center;
                margin-top: 16px;
            }

            .main-heading {
                font-size: 36px;
                font-family: 'Sofia Pro Semi';
                font-weight: 700;
                max-width: 797px;
                margin-bottom: 16px;
                line-height: normal;

                @media only screen and (max-width: 767.9px) {
                    margin-bottom: 24px;
                }

                span {
                    position: relative;
                    z-index: 1;
                    @media only screen and (max-width: 1023.9px) {
                        display: inline-block;
                    }

                    &.text-align-others{
                        display: inline-block;
                    }

                    &.highlight-text {
                        background-color: #FF880F ;
                        
                        &::after {
                            content: "";
                            position: absolute;
                            width: 100%;
                            // border-bottom: 20px solid #FF880F;
                            top: 20px;
                            left: 0px;
                            z-index: -1;

                            @media only screen and (max-width: 767.9px) {
                                // border-bottom: 12px solid #FF880F;
                                top: 12px;
                            }
                        }
                    }

                }

                @media only screen and (max-width: 1023.9px) {
                    font-size: 20px;
                    font-weight: 700;
                }
            }

            .sub-heading {
                font-size: 24px;
                font-family: Sofia Pro;
                line-height: 30px;
                font-weight: 400;

                @media only screen and (max-width: 1023.9px) {
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 16px;
                }
            }

            .web-btn {
                background: $white;
                color: #0048B5 !important;

                &:hover {
                    color: #0048B5 !important;
                }

                @media only screen and (max-width: 1023.9px) {
                    width: 100%;
                }
            }

            .video-play-btn {
                display: inline-block;
                padding: 8px 24px;
                margin-left: 16px;

                @media only screen and (max-width: 1023.9px) {
                    margin: 0;
                    margin-top: 16px;
                    color: #EFF4FC;
                    text-align: center;
                    font-size: 14px;
                    font-family: Sofia Pro;
                    line-height: 24px;
                    text-decoration-line: underline;
                }

            }
        }
    }
    .animated-banner-content{
        margin: 32px auto;
        text-align: center;
        @media only screen and (max-width: 1023.9px) {
            // margin: 0 0;
            margin-top: 64px;
        }
        .slick-arrow{
            display: none !important;
        }
        .slick-dots {
            bottom: -30px;
    
            li {
                margin: 0 3px;
    
                @media only screen and (max-width: 767.9px) {
                    margin: 0;
                }
            }
    
            li button:before {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #B2DCFF;
                border: 1px solid #B2DCFF;
                line-height: 0;
                content: "";
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
                transition: all 0.3s ease;
                @media only screen and (max-width: 991.9px) {
                    width: 5px;
                    height: 5px;
                }
            }
    
            li.slick-active button:before {
                background: #FFF;
                border: 1px solid #FFF;
                @media only screen and (max-width: 991.9px) {
                    width: 7px;
                    height: 7px;
                }
    
            }
        }
        .content-heading{
            color: #FFF;
            font-family: 'Sofia Pro Semi';
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px; /* 142.857% */
            margin-bottom: 32px;
            @media only screen and (max-width: 1023.9px) {
                font-size: 20px;
                font-weight: 700;
                line-height: 32px; 
                margin-bottom: 24px;
    
            }
        }
        .conditions-slider-wrapper{
            margin: 0 auto;
            min-width: 1200px;
            width: 100%;

            @media only screen and (max-width: 1500.9px) {
                max-width: 1200px;
                min-width: none;
            }

            @media only screen and (max-width: 1311.9px) {
                max-width: 900px;
                min-width: none;
                
            }
            @media only screen and (max-width: 1200.9px) {
                min-width: calc(100% - 32px);                
            }
            @media only screen and (max-width: 1023.9px) {
                margin: 0;
                min-width: calc(100% - 32px);

            }
             
            .conditions-slide-wrapper{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 160px !important;
                height: 196px;
                margin: 0 16px;

                @media only screen and (max-width: 1023.9px) {
                    width: 140px !important;
                    height: 176px;
                }
                
                .slide-image-wrapper{
                    display: flex;
                    width: 160px;
                    height: 160px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 200px;
                    background: #CCE8FF;

                    @media only screen and (max-width: 1023.9px) {
                        width: 140px;
                        height: 140px;
    
                    }

                    @media only screen and (max-width: 328.9px) {
                        width: 100%;
                        height: 140px;
    
                    }

                    img{
                        // width: 120px;
                        // height: 86.275px;
                        width: 100%;
                        height: 100%;
                        padding: 0 20px;
                        @media only screen and (max-width: 1023.9px) {
                            padding: 0 14px;
        
                        }
                    }
                }
            }
            h3{
                color:  #FFF;
                font-family: 'Sofia Pro Semi';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 150% */
                margin-top: 12px;
                margin-bottom: 0;
            }
            
        }
    }
}
.before-after-image-slider-wrapper{
    max-width: calc(100% - 120px);
    margin: auto;
    margin-bottom: 60px;
    position: relative;

    img{
        position: absolute;
    }

    .left-arrow-button{
        display: none;
        @media only screen and (max-width: 1024.9px) {
            left: 0;
            height: 40px;
            width: 40px;
            z-index: 10;
            bottom: -60px;
            display: block;
            transform: rotate(180deg);
        }
        
    }
    .right-arrow-button{
        display: none;
        @media only screen and (max-width: 1024.9px) {
            right: 0;
            height: 40px;
            width: 40px;
            z-index: 10;
            bottom: -60px;
            display: block;
        }
        
    }
    .arrow-disabled{
        display: none;
    }



    h3{
        color: #1E1E1E;
        /* Website/H2/Semibold */
        font-family: 'Sofia Pro Semi';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 40px; /* 142.857% */
        margin-bottom: 16px;
        @media only screen and (max-width: 991.9px) {
            font-size: 20px;
            line-height: 40px; /* 142.857% */
            margin-bottom: 8px;

        }
    }
    p{
        color: #1E1E1E;
        /* Website/P1/Regular */
        font-family: Sofia Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px; /* 150% */
        margin-bottom: 16px;

        @media only screen and (max-width: 991.9px) {
            font-size: 16px;
            line-height: 24px; /* 150% */
            margin-bottom: 24px;
        }
    }

    @media only screen and (max-width: 991.9px) {
        max-width: 100%;
        margin-bottom: 48px;
    }

    .slick-slider{
        @media only screen and (max-width: 991.9px) {
            width: calc(100% + 8px);
        }
        
        .slick-list{
            
            @media only screen and (max-width: 991.9px) {
                margin-left: -8px;
            }
        }

        .slick-slide {
            height: 248px !important;

            @media only screen and (max-width: 991.9px) {
                padding: 0 8px;
            }
        }
    }


    .before-after-slide-wrapper{
        margin: 0 auto;
        height: 248px;

        @media screen and (min-width: 1500px) and (max-width:1870px){
            width: 95% !important;
        }
        
    }

    .before-after-image-wrapper{
        width: 100%;
        height: 100% !important;

        div{
            height: 100%;
        }
    }
    .slick-dots {
        // bottom: none;
        bottom: -58px;
        transform: translate(-8px);
        @media only screen and (max-width: 919.9px) {
            bottom:-50px ;
            transform: translate(0);
        }

        li {
            margin: 0 3px;

            @media only screen and (max-width: 767.9px) {
                margin: 0;
            }
        }

        li button:before {

            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #CCE8FF;
            border: 1px solid #CCE8FF;
            line-height: 0;
            content: "";
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
            transition: all 0.3s ease;
            
            @media only screen and (max-width: 991.9px) {
                width: 6px;
                height: 6px;
            }
        }

        li.slick-active button:before {
            background: #008CFF;
            border: 1px solid #008CFF;

            @media only screen and (max-width: 991.9px) {
                width: 8px;
                height: 8px;
            }

        }
    }
    .slick-arrow {
        width: 48px;
        height: 248px;
        background-size: cover;
        background-repeat: no-repeat;

        &.slick-prev {
            height: 248px !important;
            background-image: url(/img/jp-home-page/icons/arrow-left.svg);
            background-position: left;
            background-size: contain;            
            left: -72px;

            &::before{
                content: '' !important;
                height: 248px !important;
                width: 48px !important;

                @media only screen and (max-width: 991.9px) {
                    display: none !important;
                }

                @media only screen and (max-width: 1200.9px) {
                    display: none !important;
                }
            }

            @media only screen and (max-width: 1200.9px) {
                display: none !important;
            }

            @media only screen and (max-width: 991.9px) {
                display: none !important;
            }
        }

        &.slick-next {
            background-image: url(/img/jp-home-page/icons/arrow.svg);
            background-position: right;
            background-size: contain;
            right: -72px;
            &::before{
            content: '' !important;
            height: 248px !important;
            width: 48px;

            @media only screen and (max-width: 991.9px) {
                display: none;
            }

            @media only screen and (max-width: 1200.9px) {
                display: none;
            }
        }

            @media only screen and (max-width: 1200.9px) {
                display: none !important;
            }

            @media only screen and (max-width: 991.9px) {
                display: none !important;
            }
        }

        &.slick-disabled {
            display: none !important;
        }
    }
}
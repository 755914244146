.before-after-image-wrapper {
    width: 100%;
    max-width: 375px;
    padding: 16px;
    background-color: #fff;
    @media only screen and (max-width: 991.9px) {
        max-width: 100%;

    }

    div{

        img{
            width: 100%;
            height: 100%;
        }
    }
    
    .__rcs-handle-button {
        width: 16px !important;
        height: 48px !important;
        background: #FFFFFF !important;
        border: 1px solid #E6E6E6 !important;
        border-radius: 40px !important;
        gap: 4px !important;

        div{
            border: none !important;
            height: 14px !important;
            border-right: 1px solid #E6E6E6 !important; 
        }
    }

    .__rcs-handle-line{
        width: 1px !important;
    }

    .before-after-image-bottom-content{
        margin-top: 20px;
        text-align: center;

        p{
            margin-bottom: 0;
        }
    }
}
.review-slider{
    max-width: 1384px;
    margin: 0 auto;
    height: 100%;
    padding: 72px 0px;

    @media only screen and (max-width: 1583.9px) {
        max-width: calc(100% - 64px);
        
    }

    @media only screen and (max-width: 1391.9px) {
        max-width: calc(100% - 64px);
        
    }

    @media only screen and (max-width: 1025.9px) {
        max-width: calc(100% - 52px);
        margin: 0 auto;
        
    }

    @media only screen and (max-width: 991.9px) {
        max-width: calc(100% - 72px);
        margin: 0 auto;
        
    }

    .section-heading{
        color:  #282829;
        text-align: center;
        /* Website/H1/Semibold */
        font-family: 'Sofia Pro Semi';
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 125% */
        margin-bottom: 32px;

        @media only screen and (max-width: 1025.9px) {
            font-size: 24px;
            line-height: 32px; /* 133.333% */
        }
        
    }

    .slick-dots {
        bottom: -32px;

        li {
            margin: 0 3px;

            @media only screen and (max-width: 767.9px) {
                margin: 0;
            }
        }

        li button:before {

            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #CCE8FF;
            border: 1px solid #CCE8FF;
            line-height: 0;
            content: "";
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
            transition: all 0.3s ease;
            
            @media only screen and (max-width: 991.9px) {
                width: 6px;
                height: 6px;
            }
        }

        li.slick-active button:before {
            background: #008CFF;
            border: 1px solid #008CFF;

            @media only screen and (max-width: 991.9px) {
                width: 8px;
                height: 8px;
            }

        }
    }
    
    .slick-arrow{
        
        @media only screen and (max-width: 991.9px) {
            height: 100%;
            width: 24px !important;

            &.slick-disabled {
                display: none !important;
            }
        }
    }
    .slick-next {
            right: -24px !important;
            background-image: url(/img/jp-home-page/icons/chevron-arrow-right.svg);
            background-position: right;
            background-size: contain;   
            background-repeat:no-repeat;
        @media only screen and (max-width: 991.9px) {
            
        }
        
        &::before{
            display: none;

            @media only screen and (max-width: 991.9px) {
                content: '' !important;
                display: none;  
            }
            
           
        }
    }
    .slick-prev {
        // display: none !important;
        left: -24px !important;
        background-image: url(/img/jp-home-page/icons/chevron-arrow-left.svg);
        background-position: left;
        background-size: contain;   
        background-repeat:no-repeat;

        @media only screen and (max-width: 991.9px) {

           
        }

        &::before{
            display: none;

            @media only screen and (max-width: 991.9px) {
                content: '' !important;
                display: none;
            }
            
        }
    }

    .slick-disabled{
        display: none !important;
    }

    a {
        all: unset;
    }

    .review-rating{
        cursor: pointer;
        display: flex;
        margin: 32px auto 0 auto;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        @media only screen and (max-width: 991.9px) {
            margin-top: 58px;
            
        }
        .review-provider{
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            border-right: 1px solid #E6E6E6;
            padding-right: 8px;
    
            p{
                color:#86868B;
                /* Website/H4/Regular */
                font-family: Sofia Pro;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */
                margin-left: 8px;
                margin-bottom: 0px;
            }
            img{
                height: 20px;
                width: 20px;
            }
        }
        .star-ratings {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 8px;

            p {
                font-family: "Sofia Pro Semi", sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
                color: #86868B;
                margin-bottom: 0;

                @media only screen and (max-width: 767.9px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            img {
                margin: 8px 0 14px 12px;

                @media only screen and (max-width: 767.9px) {
                    margin: 4px 0 8px 8px;
                }
            }
        }
    }

}
.location-select-wrapper {
    position: relative;
    .location-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: #FFF;
        border: 1px solid #E6E6E6;

        span {
            font-family: 'Sofia Pro Semi';
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            margin-top: 2px;
        }
    
        img {
            transform: rotate(0deg);
            transition: all 0.3s ease;
            &.rotate {
                transform: rotate(-180deg);
            }
        }
    }
    
    .location-options {
        position: absolute;
        border-radius: 8px;
        box-shadow: 4px 8px 16px 0px rgba(142, 153, 168, 0.15);
        background-color: #FFF;
        z-index: 10;
        width: 100%;
        margin-top: 4px;
        display: none;
        padding: 8px;


        &.open {
            display: block;
        }

        .location-option {
            margin: 0;
            padding: 12px 16px;
            color: #282829;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            transition: background-color 0.2s ease;
            cursor: pointer;
    
            &:hover {
                background-color: #E6E6E6;
            }

            &.selected {
                font-family: 'Sofia Pro Semi';
                font-weight: 700;
                color: #008CFF;
            }
        }

    }
}

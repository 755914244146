@import "../globals/media-query.scss";
@import "../globals/colors.scss";

.jp-faq-section-wrapper {
  background-color: #FAFAFA;
  padding: 72px 208px;
}
  .faq-section.new-faq {
    background: $white 0% 0% no-repeat padding-box;
    padding: 32px;
    margin-bottom: 32px;
    background-color: #FFF;

    h2 {
      font: normal normal 700 32px/40px 'Sofia Pro Semi';
      letter-spacing: 0px;
      color: $Jaguar;
      margin-bottom: 0;
      text-align: center;
    }
  }

  .faq-collapse-wrapper {
    .collapse-body {
      padding: 32px 0 0;
      font-weight: normal normal 700 16px/24px 'Sofia Pro Semi';
      p,
      ol,
      ul {
        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        &:hover {
          text-decoration: none;
          color: $zenyum_blue;
        }
      }

      strong {
        font-weight: 500;
        font-size: 16px;
      }
    }
    
    .collapse-wrapper {
      border-bottom: 1px solid #E6E6E6;
      .collapse-header{
        h3{
          font: normal normal 400 20px/32px Sofia Pro;
        }
        .plus{
          top: 32px;
        }
      }
      &.active{
        .collapse-header{
          h3{
            font-family: 'Sofia Pro Semi';
            font-weight: 700;
          }
        }
        
      }
    }
    
    // &:last-child{
    //   .collapse-wrapper{
    //     border-bottom: none;
    //   }
    // }
  }
  


@media screen and (max-width:991.9px) {

  .jp-faq-section-wrapper {
    background-color: #FFF;
    padding: 0;
  }

  .faq-section.new-faq {
    background:$white 0% 0% no-repeat padding-box;
    border-radius: 0px;
    padding: 32px 16px;
    border-radius: 15px;
    margin: 0;

    h2 {
      font: normal normal 700 24px/32px 'Sofia Pro Semi';
      letter-spacing: 0px;
      color: $Jaguar;
      margin-bottom: 24px;
      text-align: center;
    }

    .faq-collapse-wrapper {
      .collapse-wrapper{
        padding: 16px 0px;
        .collapse-header{
          h3{
            font: normal normal 400 16px/24px Sofia Pro;
          }
          .plus{
            top: 16px;
            &::before, &::after{
              height: 12px;
              bottom: 0;
            }
          }
        }
        &.active{
          .collapse-header{
            h3{
              font-family: 'Sofia Pro Semi';
              font-weight: 700;
            }
          }
        }
        .collapse-body{
          padding: 16px 0 0;
          font: normal normal 400 14px/24px Sofia Pro;
        }
      }
    }
  }

}
.slider-card-wrapper{
    display: flex;
    padding: 32px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    border-radius: 16px;
    background: #008CFF;
    min-height: 160px;
    
    @media only screen and (max-width: 1583.9px) {
        margin: 0 12px 8px 12px;
        gap: 0px;
        padding: 16px;
        flex-direction: column-reverse;
        justify-content: flex-end;

     }

     @media only screen and (max-width: 1023.9px) {
        flex-direction:row ;
        margin: 0 0 8px 0;
     }

    .slider-card-content{
        display: flex;
        flex-direction:column;

        @media only screen and (max-width: 1583.9px) {
            text-align: center;
    
         }

        @media only screen and (max-width: 1023.9px) {
            margin-right: 8px;
            text-align: left;
         }

        .slider-card-heading{
            color: #E6F4FF;
            font-family: 'Sofia Pro Semi';
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            margin-bottom: 4px;
            @media only screen and (max-width: 1023.9px) {
                font-size: 16px;
                line-height: 24px;
             }
            
        }
        
        .slider-card-subcontent{      
            color:  #E6F4FF;
            font-family: Sofia Pro;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            @media only screen and (max-width: 1023.9px) {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
             }
        }
        
    }
    .slider-card-image{
        min-width: 160px;
        height: 160px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
    
        @media only screen and (max-width: 1583.9px) {
            margin-bottom:  16px;
        }

        @media only screen and (max-width: 1023.9px) {
           height: 88px;
           min-width: 88px;
           margin: 34px 0;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }

}
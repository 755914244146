.smile-journey-section {
    text-align: center;
    padding: 60px 103px;
    overflow: hidden;

    @media screen and (max-width:1400px) {
        padding: 48px 32px;
    }

    @media screen and (max-width:986px) {
        padding: 48px 16px;
    }

    h2 {
        color: #1E1E1E;
        font-family: 'Sofia Pro Semi';
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 32px;

        @media screen and (max-width:768px) {
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 24px;
        }

    }

    .cards-wrapper {
        margin: 0 auto;

        @media screen and (max-width: 480px) {
            width: calc(100% + 8px);
        }

        .smile-journey-cards {
            .slick-list {
                overflow: visible;

                @media screen and (max-width: 480px) {
                    margin-left: -8px;
                }
            }

            .slick-track {
                margin: 0 auto;
                min-height: 523px;

                @media screen and (max-width:768px) {
                    min-height: auto;
                }
            }

            .slick-slide {
                max-height: 483px;
            }

            .slick-dots {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translateX(-4px);

                li {
                    background: #CCE8FF;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    margin: 0 12px 0 0;

                    &:last-child {
                        margin-right: 0;
                    }

                    button {
                        width: 100%;
                        height: 100%;
                        &::before {
                            opacity: 0;
                        }
                    }

                    &.slick-active {
                        width: 8px;
                        height: 8px;
                        background: #008CFF;
                        transform: translateY(-1px);
                    }
                }
            }
        }
    }
}
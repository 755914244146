.smile-journey-card {
    border-radius: 32px;
    box-shadow: 2px 4px 8px 0px rgba(142, 153, 168, 0.25);
    padding: 42.5px 32px;
    width: 384px;
    background-color: white;
    margin: 0 auto;

    @media screen and (max-width: 480px){
        padding: 24px 16px; 
        height: 483px;
        width: calc(100% - 16px);
    }

    .card-image-container {
        border-radius: 16px;
        height: 320px;
        background-size: cover;
        background-position: center;

        @media screen and (max-width: 768px){
            height: 311px;
        }

        img {
            border-radius: 16px;
            height: 100%;
            width: 100%;
        }
    }

    .card-title {
        color: #008CFF;
        font-family: 'Sofia Pro Semi';
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin: 10px 0;
        text-align: center;
    }

    .card-content {
        color: #282829;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        margin-bottom: 0;
    }
}
.details-section-wrapper {
    padding:  60px 103px;

    @media only screen and (max-width: 671.9px) {
        padding: 32px 16px;
    }

    h2{
        text-align: center;
        color:  #282829;
        /* Mobile/H3/Semi-bold */
        font-family: 'Sofia Pro Semi';
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 160% */
        margin-bottom: 24px;

        @media only screen and (max-width: 1025.9px) {
            line-height: 32px;
            font-size: 20px;
        }
    }

    .details-content-wrapper{
        display: flex;
        // flex-wrap: wrap;
        row-gap: 16px;
        gap: 32px;
        max-width: calc(100% - 124px);
        margin: 0 auto;

        @media only screen and (max-width: 1299.9px) {
            padding: 0 0;
            max-width: 100%;
        }
        

        @media only screen and (max-width: 1025.9px) {
            flex-direction: column;
            gap: 0;
            padding: 0 0;
        }
    }

    .review-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 24px;
        @media only screen and (max-width: 767.9px) {
            // padding: 24px ;
        }
        

        .google-review {
            display: inline-block;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #E6E6E6;

            img {
                width: 20px;
                height: 20px;
                margin: 6px 8px 10px 0;
                display: inline-block;

                @media only screen and (max-width: 767.9px) {
                    width: 14px;
                    height: 14px;
                    margin: 5px 4px 5px 0;
                }
            }

            p {
                font-family: 'Sofia Pro';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 0;
                color: #86868B;
                margin-right: 8px;
                display: inline-block;
                letter-spacing: -1px;

                @media only screen and (max-width: 767.9px) {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

        .star-rating {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;

            p {
                font-family: "Sofia Pro Semi", sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 40px;
                color: #86868B;
                margin-bottom: 0;

                @media only screen and (max-width: 767.9px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            img {
                margin: 8px 0 14px 12px;

                @media only screen and (max-width: 767.9px) {
                    margin: 4px 0 8px 8px;
                }
            }
        }
    }
    .sponsor-section{
        display: flex;
        gap: 88px;
        align-items: center;
        max-width: 1007px;
        margin: 56px auto 24px auto;
        height: 88px;
        @media only screen and (max-width: 1300.9px){
            gap:40px
        }

        @media only screen and (max-width: 1024.9px){
            flex-wrap: wrap;
            height: auto;
            gap: 32px;
            justify-content: space-around;
        }

        @media only screen and (max-width: 791.9px) {
            padding: 32px 0;
            flex-wrap: wrap;
            row-gap: 16px;
            gap: 24px;
            // justify-content: space-around;
            margin: 10px 0;
            height: auto;
            align-items: center;
            justify-items: center;
            justify-content: center;
        }
        .yahoo{
            transform: scale(1.6) translateX(8px);
            @media only screen and (max-width: 791.9px) {
                transform: scale(1.6) translateX(-4px);
            }
        }
        .news-pick{
            transform: scale(0.8);
            object-fit: inherit;

            @media only screen and (max-width: 791.9px) {
                transform: scale(1) translateX(22px);
            }
        }

        .slide-wrapper{
            width: 100%;
            height: 100%;
            padding: 0 8px;


            @media only screen and (max-width: 1024.9px){
                width: 26%;
                height: 88px;
                
            }
            @media only screen and (max-width: 791.9px) {
                min-width: 88px;
                width: 28%;
                height: 100%;
                min-height: 32px;
                
            }

            
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                // @media only screen and (max-width: 1024.9px){
                //     object-fit: inherit;
                // }
            }
            &:last-of-type {
                width: 100%;
                height: 88px;
                // object-fit: inherit;
                @media only screen and (max-width: 1024.9px){
                    max-width: 88px;
                    height: 60px;
                }
                
            }
        }
    }

    // .sponsor-section {
    //     // padding-top: 32px;
    //     margin: 0 auto;

    //     // @media only screen and (max-width: 767.9px) {
    //     //     padding-top: 24px;

    //     // }

    //     .slick-list {
    //         // max-height: 64px;
    //     }

    //     .slick-track {
    //         margin: 0 auto;

    //         div {
    //             width: 160px;
    //             height: 64px;

    //             @media only screen and (max-width: 767.9px) {
    //                 width: 104px;
    //                 height: 40px;

    //             }
    //         }
    //     }

    //     .slick-slide {
    //         text-align: center;
    //         margin: 0;

    //         div {
    //             margin: 0 auto;
    //         }
    //     }

    //     .slide-wrapper {
    //         display: flex !important;
    //         justify-content: center;
    //         align-items: center;
    //         height: 100%;
    //         margin-right: 0;

    //         img {
    //             margin: 0 auto;
    //             max-width: 128px;
    //             max-height: 48px;

    //             @media only screen and (max-width: 767.9px) {
    //                 max-height: 32px;
    //                 max-width: 80px;
    //             }
    //         }
    //     }
    // }
}
.treatment-process-section {
    padding: 60px 130px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        padding: 48px 16px;
    }

    h2 {
        font-size: 28px;
        font-family: 'Sofia Pro Semi';
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        margin-bottom: 54px;

        @media screen and (max-width: 768px) {
            font-size: 20px;
            line-height: 32px;
            margin-bottom: 40px;
        }
    }

    .treatment-step-nos {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .step-no {
        color: #2CAAE2;
        background: #E6F4FF;
        user-select: none;
        border-radius: 80px;
        height: 60px;
        width: 60px;
        padding: 10px;
        cursor: pointer;

        @media screen and (max-width: 862px){
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: 50px;
        }

        @media screen and (max-width: 768px) {
            width: 40px;
            height: 40px;
            padding: 10px;
        }

        p {
            font-family: 'Sofia Pro Semi';
            font-size: 28px;
            font-weight: 700;
            line-height: 40px;
            text-align: center;
            margin: 2px 2px 0 0;

            @media screen and (max-width: 862px) {
                font-size: 20px;
                line-height: 32px;
            }
        }

        &.active {
            background: #008CFF;
            color: #FFF;

            @media screen and (max-width: 768px) {
                width: 52px;
                height: 52px;
                padding: 8px;

                p {
                    font-size: 24px;
                }

            }
        }
    }

    .step-divider {
        border-top: 1px dashed #2CAAE2;
        margin-bottom: 2px;
        width: 40px;

        &:last-child {
            display: none;
        }
    }

    .treatment-steps-slider {
        display: none;

        @media screen and (max-width:768px) {
            display: block;
        }

        .slick-list {
            overflow: visible;

            .slick-slide {
                padding: 22px 16px;

                &.slick-active {
                    padding: 16px 16px;
                }
            }
        }
    }

    .total-steps {
        text-align: center;
        margin: 12px 0 0 0;
        display: none;
        font-family: 'Sofia Pro Semi';
        font-weight: 700;
        font-size: 14px;

        @media screen and (max-width:768px) {
            display: block;
        }
    }

    .treatment-process-slider {
        padding-top: 40px;

        @media screen and (max-width: 768px) {
            padding-top: 24px;
        }

        .slick-track {
            @media screen and (max-width: 768px) {
                margin-left: -18px;
            }
        }

        .slick-list {
            overflow: visible;

            @media screen and (max-width: 768px) {
                transform: translateX(18px);
            }

            .slick-slide {
                padding: 76px 24px;

                @media screen and (max-width: 986px) {
                    padding: 25px 12px;
                    width: 266px;
                }

                @media screen and (min-width: 987px) {
                    width: 568px !important;
                }

                .treatment-card {
                    display: flex !important;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 32px;
                    border: 1px solid #E6E6E6;
                    background-color: #FFF;
                    padding: 24px 32px;
                    gap: 36px;
                    color: #D7D7D7;
                    cursor: pointer;
                    min-height: 216px;

                    .card-content {
                        flex: 1;

                        .step-number {
                            font-size: 56px;
                            font-weight: 400;
                            line-height: 42px;
                        }

                        .card-text {
                            font-family: 'Sofia Pro Semi';
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 32px;
                            min-width: 260px;
                        }

                        .smile-assessment-btn {
                            background-color: #E0E0E1;
                            color: #86868B;
                            border-radius: 48px;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 24px;
                            padding: 8px 24px;
                            text-align: center;
                            text-decoration: none;
                            margin-top: 16px;
                            display: inline-block;
                        }
                    }

                    .card-img-container {
                        height: 160px;
                        width: 160px;
                        position: relative;

                        img {
                            height: 100%;
                            width: 100%;
                            opacity: 0.8;
                        }

                        .svg-overlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            fill: #282829;
                            mix-blend-mode: saturation;
                        }

                    }

                    @media screen and (max-width: 986px) {
                        flex-direction: column-reverse;
                        padding: 20px;
                        height: 328px;
                        gap: 20px;
                        justify-content: flex-end;
                        min-height: fit-content;

                        .card-img-container {
                            height: 88px;
                            width: 88px;

                            img {
                                height: 100%;
                                width: 100%;
                            }
                        }

                        .card-content {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 100%;

                            .step-number {
                                font-family: 'Sofia Pro Semi';
                                font-size: 40px;
                                line-height: 56px;
                                margin-bottom: 0;
                                font-weight: 700;
                            }

                            .card-text {
                                font-family: 'Sofia Pro Semi';
                                font-weight: 700;
                                min-width: auto;
                            }

                            .smile-assessment-btn {
                                display: block;
                                margin-top: 0;
                            }

                        }

                    }

                }

                &.slick-current {
                    padding: 24px 24px;

                    @media screen and (min-width: 987px) {
                        width: 648px !important;
                    }

                    @media screen and (max-width: 986px) {
                        padding: 0px 12px;
                        width: 286px;
                    }

                    .treatment-card {
                        color: #000;
                        border: 1px solid #CCE8FF;
                        box-shadow: 2px 4px 8px 0px rgba(142, 153, 168, 0.25);
                        padding: 32px;
                        cursor: default;
                        min-height: 320px;

                        .card-content {
                            .step-no {
                                line-height: 72px;
                            }

                            .smile-assessment-btn {
                                background-color: #008CFF;
                                color: #FFF;
                                padding: 8px 24px;
                            }
                        }

                        .card-img-container {
                            height: 220px;
                            width: 220px;

                            img {
                                opacity: 1;
                            }

                            .svg-overlay {
                                display: none;
                            }
                        }

                        @media screen and (max-width: 986px) {
                            flex-direction: column-reverse;
                            padding: 32px 20px;
                            height: 378px;
                            min-height: fit-content;

                            .card-img-container {
                                height: 110px;
                                width: 110px;
                            }

                            .card-content {

                                .step-number {
                                    font-size: 40px;
                                    line-height: 56px;
                                }


                            }

                        }
                    }
                }
            }
        }
    }

    .line-button {
        background: #4CC764;
        border-radius: 48px;
        display: block;
        margin: 40px auto 0 auto;
        padding: 12px 24px;
        text-decoration: none;
        width: fit-content;
        text-align: center;

        img {
            height: 24px;
            width: 24px;
        }

        span {
            color: white;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            margin-left: 8px;
        }

        @media screen and (max-width: 480px) {
            width: 100%;
            padding: 8px 16px;
        }
    }

}
@import "../globals/colors.scss";

.homenew-layout-jp {
    margin-top: 112px;

    @media only screen and (max-width: 1055.9px) {
        margin-top: 104px;
    }
    
    .overflow-hidden {
        overflow: hidden;
    }

    .text-align-center {
        text-align: center;
    }

    .display-inline-block {
        display: inline-block;
    }

    h2.display-inline-block {
        max-width: calc(100% - 100px);
    }

    .cds--grid {
        @media only screen and (max-width: 671.9px) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    a.web-btn.jp-banner-btn {
        padding: 8px 24px;
        font-size: 14px;
        line-height: 24px;
        color: #FFF;
        background: #008CFF;
        border: none;
        border-radius: 48px;
        user-select: none;
        min-width: 327px;
        text-align: center;
        transition: none;

        @media only screen and (max-width: 1055.9px) {
            font-size: 14px;
            line-height: 24px;
        }

        @media only screen and (max-width: 671.9px) {
            display: block;
            width: 100%;
        }

        &:hover {
            background: $Jaguar;
            color: $white;
        }
    }

    a.learn-more,
    a.see-all {
        padding: 8px 24px;
        font-size: 16px;
        line-height: 1.5;
        color: $white;
        text-decoration: none;
        user-select: none;
        position: relative;
        border-radius: 48px;

        @media only screen and (max-width: 1055.9px) {
            font-size: 14px;
            line-height: 24px;
        }

        &:hover {
            background: $white;
            color: $Jaguar;
        }

        &::after {
            content: "";
            position: absolute;
            width: calc(100% - 48px);
            border-bottom: 1px solid;
            bottom: 12px;
            left: 24px;

            @media only screen and (max-width: 671.9px) {
                bottom: 13px;
            }
        }
    }

    a.see-all {
        color: $zenyum_blue;
        display: inline-block;
        float: right;
        margin-right: -24px;

        @media only screen and (max-width: 671.9px) {
            margin-top: -4px;
        }

        &:hover {
            background: $white;
            color: $zenyum_blue;
        }
    }

    .homenew-buttonbox {
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        .sgmy-btn {
            background-color: #005AE2;
            color: $white;
        }

        .sgmy-link {
            color: #005AE2;
        }
    }

    &__banner {
        height: 640px;
        background-size: cover;
        background-position: center;
        color: $white;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media only screen and (max-width: 1919.9px) {
            padding-bottom: 33.33%;
            height: 0;
            display: block;
            position: relative;
        }

        @media only screen and (max-width: 1583.9px) {
            padding-bottom: 35%;
        }

        @media only screen and (max-width: 1311.9px) {
            padding-bottom: 40%;
        }

        @media only screen and (max-width: 1055.9px) {
            padding-bottom: 0;
            height: 400px;
        }

        @media only screen and (max-width: 671.9px) {
            height: 536px;
            // margin-top: 88px;
        }

        >div {
            margin-bottom: 80px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            max-width: 35%;
            text-align: center;

            @media only screen and (max-width: 1919.9px) {
                position: absolute;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                bottom: 0;
            }

            @media only screen and (max-width: 1583.9px) {
                margin-bottom: 64px;
            }

            @media only screen and (max-width: 1311.9px) {
                margin-bottom: 40px;
            }

            @media only screen and (max-width: 1055.9px) {
                max-width: 370px;
                margin-bottom: 32px;
            }

            @media only screen and (max-width: 671.9px) {
                max-width: 100%;
                padding: 0 16px;
                margin-bottom: 40px;
                text-align: center;
            }
        }

        h1 {
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
            margin: 0;
            font-family: Sofia Pro Semi;

            @media only screen and (max-width: 1583.9px) {
                font-size: 32px;
            }

            @media only screen and (max-width: 1311.9px) {
                font-size: 32px;
            }

            @media only screen and (max-width: 1055.9px) {
                font-size: 28px;
            }

            @media only screen and (max-width: 671.9px) {
                font-size: 28px;
            }
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            margin-top: 16px;

            @media only screen and (max-width: 1055.9px) {
                margin-top: 12px;
            }

            @media only screen and (max-width: 671.9px) {
                margin-top: 8px;
            }

            strong {
                display: block;
                font-family: 'Sofia Pro Semi';
                margin-top: 8px;

                @media only screen and (max-width: 671.9px) {
                    margin-top: 0;
                }
            }
        }

        &.sg-my-banner {

            h1,
            p {
                color: #005AE2;

                @media only screen and (max-width: 1055.9px) {
                    color: $white;
                }
            }
        }

        &.desktop-Dark {

            h1,
            p {
                @media only screen and (min-width: 1056px) {
                    color: $Jaguar
                }
            }
        }

        &.desktop-Light {

            h1,
            p {
                @media only screen and (min-width: 1056px) {
                    color: $white;
                }
            }
        }

        &.mobile-Dark {

            h1,
            p {
                @media only screen and (max-width: 1055.9px) {
                    color: $Jaguar;
                }
            }
        }

        &.mobile-Light {

            h1,
            p {
                @media only screen and (max-width: 1055.9px) {
                    color: $white;
                }
            }
        }

        &.mobile-Zenyum-Blue{

            h1,
            p {
                @media only screen and (max-width: 1055.9px) {
                    color: #005AE2;
                }
            }
        }

        &.desktop-Zenyum-Blue {

            h1,
            p {
                @media only screen and (min-width: 1056px) {
                    color: #005AE2;
                }
            }
        }

        a.web-btn {
            margin-top: 32px;

            @media only screen and (max-width: 1311.9px) {
                margin-top: 32px;
            }

            @media only screen and (max-width: 1055.9px) {
                margin-top: 24px;
            }

            @media only screen and (max-width: 671.9px) {
                margin-top: 32px;
            }
        }

        a.learn-more {
            margin-top: 8px;

            @media only screen and (max-width: 1055.9px) {
                margin-top: 4px;
            }

            @media only screen and (max-width: 671.9px) {
                margin-top: 8px;
            }
        }
    }

    .home-layout-animatedbanner {
        position: relative;

        video {
            width: 100%;
        }

        .main-banner-video {
            @media only screen and (max-width: 1055.9px) {
                display: none;
            }
        }

        .main-banner-video--mobile {
            @media only screen and (min-width: 1056px) {
                display: none;
            }

        }

        .animated-content-wrapper {
            position: absolute;
            width: 100%;
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            z-index: 1;

            @media only screen and (max-width: 1055.9px) {
                align-items: flex-end;
                padding: 0 16px 48px 16px;
                height: calc(100% - 8px);
            }

            .animated-content {
                color: $white;
                text-align: center;
                font-family: 'Sofia Pro Semi';

                h1 {
                    font-family: 'Sofia Pro Semi';
                    font-weight: 700;
                    font-size: 48px;
                    line-height: 48px;
                    margin: 0 auto;
                    margin-bottom: 16px;
                    max-width: 405px;

                    @media only screen and (max-width: 1055.9px) {
                        font-size: 28px;
                        line-height: 40px;
                        margin: 0;
                        max-width: 345px;
                    }
                }

                p {
                    margin: 0;
                    padding: 0;
                    font-weight: 300;
                    font-size: 20px;
                    font-family: Sofia Pro;
                    line-height: 32px;

                    @media only screen and (max-width: 1055.9px) {

                        font-size: 16px;
                        font-family: Sofia Pro;
                        line-height: 24px;
                    }
                }

                .web-btn {
                    margin-top: 24px;
                    color: #005AE2;
                    width: 352px;
                    padding: 8px 24px;
                }
            }
        }
    }

    &__content {
        margin-top: 72px;

        &.content-margin-less {
            margin-top: 40px;
        }

        @media only screen and (max-width: 1583.9px) {
            margin-top: 64px;
        }

        @media only screen and (max-width: 1311.9px) {
            margin-top: 48px;
        }

        @media only screen and (max-width: 1055.9px) {
            margin-top: 40px;

            // .cds--row {
            //     margin-left: -0.5rem;
            //     margin-right: -0.5rem;
            // }

            .cds--col-sm-4 {
                // padding-right: 0.5rem;
                // padding-left: 0.5rem;
                flex-basis: 100%;

                &:not(:first-of-type) {
                    margin-top: 16px;
                }
            }
        }

        @media only screen and (max-width: 671.9px) {
            margin-top: 32px;
        }

        h2 {
            font-family: 'Sofia Pro Semi';
            font-size: 32px;
            line-height: 40px;
            color: $Jaguar;
            font-weight: 700;
            margin: 0;

            br {
                display: none;
            }

            @media only screen and (max-width: 1583.9px) {
                font-size: 28px;
            }

            @media only screen and (max-width: 1311.9px) {
                font-size: 24px;
                line-height: 1.5;
            }

            @media only screen and (max-width: 1055.9px) {
                font-size: 22px;
            }

            @media only screen and (max-width: 671.9px) {
                font-size: 20px;
                line-height: 1.6;

                br {
                    display: block;
                }
            }
        }

        .image-box {
            background-size: cover;
            background-position: center;
            border-radius: 16px;
        }

        .content-box-small {
            margin-top: 24px;

            @media only screen and (max-width: 1055.9px) {
                margin-top: 16px;
                // padding-left: 0.5rem;
                // padding-right: 0.5rem;
            }

            @media only screen and (max-width: 671.9px) {
                &:not(:first-of-type) {
                    margin-top: 8px;
                }
            }

            p {
                font-size: 20px;
                line-height: 1.6;
                color: $white;
                margin: 0;
                position: relative;
                z-index: 1;

                @media only screen and (max-width: 1583.9px) {
                    font-size: 18px;
                }

                @media only screen and (max-width: 1055.9px) {
                    font-size: 16px;
                    line-height: 1.5;
                }
            }

            a {
                height: 128px;
                display: flex;
                align-items: center;
                padding-left: 80px;
                text-decoration: none;
                padding-top: 2px;
                position: relative;
                border-radius: 16px;
                overflow: hidden;

                @media only screen and (max-width: 1583.9px) {
                    padding-left: 48px;
                    height: 118px;
                }

                @media only screen and (max-width: 1311.9px) {
                    padding-left: 32px;
                    height: 108px;
                }

                @media only screen and (max-width: 1055.9px) {
                    padding-left: 32px;
                    height: 98px;
                }

                @media only screen and (max-width: 671.9px) {
                    padding-left: 24px;
                    height: 88px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(40, 40, 41, 0.3);
                    left: 0;
                    top: 0;
                    opacity: 0;
                }

                &:hover::after {
                    opacity: 1;
                }
            }
        }

        .content-box-large {
            height: 640px;
            display: flex;
            padding-left: 80px;
            padding-top: 80px;

            @media only screen and (max-width: 1919.9px) {
                padding-bottom: 33.33%;
                height: 0;
                display: block;
                position: relative;
            }

            @media only screen and (max-width: 1583.9px) {
                padding-left: 48px;
                padding-top: 48px;
            }

            @media only screen and (max-width: 1311.9px) {
                padding-left: 32px;
                padding-top: 32px;
            }

            @media only screen and (max-width: 1055.9px) {
                padding-bottom: 50%;
            }

            @media only screen and (max-width: 671.9px) {
                padding-left: 24px;
                padding-top: 24px;
                padding-bottom: 0;
                height: 520px;
            }

            // >div {
            //     width: calc(100% - 80px);

            //     @media only screen and (max-width: 1919.9px) {
            //         position: absolute;
            //         left: 80px;
            //         top: 80px;
            //         width: calc(100% - (80px * 2));
            //     }

            //     @media only screen and (max-width: 1583.9px) {
            //         left: 48px;
            //         top: 48px;
            //         width: calc(100% - (48px * 2));
            //     }

            //     @media only screen and (max-width: 1311.9px) {
            //         left: 32px;
            //         top: 32px;
            //         width: calc(100% - (32px * 2));
            //     }

            //     @media only screen and (max-width: 671.9px) {
            //         left: 24px;
            //         top: 24px;
            //         width: calc(100% - (24px * 2));
            //     }
            // }

            h3 {
                font-size: 40px;
                line-height: 1.4;
                font-weight: 500;
                margin: 0;

                @media only screen and (max-width: 1583.9px) {
                    font-size: 34px;
                }

                @media only screen and (max-width: 1311.9px) {
                    font-size: 28px;
                    line-height: 1.5;
                }

                @media only screen and (max-width: 1055.9px) {
                    font-size: 24px;
                }

                @media only screen and (max-width: 671.9px) {
                    font-size: 20px;
                    line-height: 1.6;
                }

                &.heading-small {
                    font-size: 32px;
                    line-height: 1.25;
                    color: $Jaguar;

                    @media only screen and (max-width: 1583.9px) {
                        font-size: 28px;
                    }

                    @media only screen and (max-width: 1311.9px) {
                        font-size: 24px;
                        line-height: 1.5;
                    }

                    @media only screen and (max-width: 1055.9px) {
                        font-size: 22px;
                    }

                    @media only screen and (max-width: 671.9px) {
                        font-size: 20px;
                        line-height: 1.6;
                    }
                }
            }

            p {
                font-size: 16px;
                line-height: 1.5;
                margin: 0;
                margin-top: 16px;
                max-width: 80%;

                @media only screen and (max-width: 1311.9px) {
                    max-width: 90%;
                }

                br {
                    display: none;

                    @media only screen and (max-width: 671.9px) {
                        display: block;
                    }
                }

                @media only screen and (max-width: 671.9px) {
                    margin-top: 8px;
                    max-width: 100%;
                }
            }

            &--half {
                p br {
                    display: block;
                }

                @media only screen and (max-width: 1919.9px) {
                    padding-bottom: 76.1%;
                }

                @media only screen and (max-width: 1055.9px) {
                    padding-bottom: 50%;
                }

                @media only screen and (max-width: 671.9px) {
                    padding-bottom: 0;

                    p br {
                        display: none;
                    }
                }
            }

            a.web-btn {
                margin-top: 40px;
            }

            a.learn-more {
                margin-top: 8px;

                @media only screen and (max-width: 1055.9px) {
                    color: $Jaguar;
                }
            }
        }

        [class*="col-"] {
            @media only screen and (min-width: 1056px) {
                &:not(:nth-of-type(-n+2)) {
                    .content-box-large--half {
                        margin-top: 32px;
                    }
                }
            }

            @media only screen and (max-width: 1055.9px) {
                &:not(:nth-of-type(-n+1)) {
                    .content-box-large--half {
                        margin-top: 16px;
                    }
                }
            }
        }
    }

    &__bannersection {
        margin-top: 72px;

        @media only screen and (max-width: 1583.9px) {
            margin-top: 64px;
        }

        @media only screen and (max-width: 1311.9px) {
            margin-top: 48px;
        }

        @media only screen and (max-width: 1055.9px) {
            margin-top: 40px;
        }

        @media only screen and (max-width: 671.9px) {
            margin-top: 32px;
        }

        .homenew-layout-jp__content {
            margin-top: 32px;

            @media only screen and (max-width: 1583.9px) {
                margin-top: 24px;
            }

            @media only screen and (max-width: 1055.9px) {
                margin-top: 16px;
            }

            &:first-of-type:last-of-type {
                margin-bottom: 72px;

                @media only screen and (max-width: 1583.9px) {
                    margin-bottom: 64px;
                }

                @media only screen and (max-width: 1311.9px) {
                    margin-bottom: 48px;
                }

                @media only screen and (max-width: 1055.9px) {
                    margin-bottom: 40px;
                }

                @media only screen and (max-width: 671.9px) {
                    margin-bottom: 32px;
                }
            }
        }
    }
}

.notification-enabled{
    margin-top: 152px;
    @media only screen and (max-width: 1055.9px) {
        margin-top: 144px;
    }
}
.animated-banner{
    text-align: center;
    max-width: 1920px;
    padding: 60px 103px;
    gap: 32px;
    background-color: #E6F4FF;
    
    @media only screen and (max-width: 1055.9px) {
        // margin: 0 16px;
        padding: 32px 16px;
    }

    h2{
        color: #1E1E1E;
        /* Website/H2/Semibold */
        font-family: 'Sofia Pro Semi';
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 142.857% */
        margin-bottom: 16px;

        @media only screen and (max-width: 1055.9px) {
            font-size: 20px;
            line-height: 32px; /* 160% */
            color: #282829;
        }
       
    }
    p{
        color: #1E1E1E;
        /* Website/P1/Regular */
        font-family: Sofia Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-bottom: 32px;

        @media only screen and (max-width: 1055.9px) {
            font-size: 16px;
            line-height: 24px; /* 150% */
            color: #282829;
            }
    }
}

.banner-skeleton-loading {
    content: '';
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5%;
    width: 100%;
    height: 640px;
    animation: skeleton-loading 2s linear infinite alternate;
    position: relative;

    .banner-content {
        height: 70px;
        width: 30%;
        border-radius: 20px;
        animation: skeleton-loading1 2s linear infinite alternate;
        position: absolute;
        top: 35%;

        @media only screen and (max-width: 991.9px) {
            width: calc(100% - 30%);
        }
    }
    .banner-sub-content {
        height: 30px;
        width: 40%;
        border-radius: 20px;
        animation: skeleton-loading1 1.5s linear infinite alternate;
        position: absolute;
        top: 55%;

        @media only screen and (max-width: 1311.9px) {
            top: 60%;
        }

        @media only screen and (max-width: 991.9px) {
            width: calc(100% - 15%);
            top: 65%;
        }
    }

    @media only screen and (max-width: 1919.9px) {
        padding-bottom: 33.33%;
        height: 0;
    }

    @media only screen and (max-width: 1583.9px) {
        padding-bottom: 35%;
    }

    @media only screen and (max-width: 1311.9px) {
        padding-bottom: 40%;
    }

    @media only screen and (max-width: 1055.9px) {
        padding-bottom: 0;
        height: 400px;
    }

    @media only screen and (max-width: 671.9px) {
        height: 536px;
    }
}

@keyframes skeleton-loading {
    0% {
        background-color: #f0f0f0;
    }

    100% {
        background-color: #dedada;
    }
}

@keyframes skeleton-loading1 {
    0% {
        background-color: #dedada;
    }

    100% {
        background-color: #f0f0f0;
    }
}


.cta-banner-section{
    background-color: #E5F3FE;
    width: 100%;
    min-height: 560px;

     @media only screen and (max-width: 991.9px) {
        min-height: auto;
    }

    .cta-banner-two-wrapper{
        display: flex;
        align-items: center;
        gap: 102px;
        align-self: stretch;
        margin: 0 auto;
        max-width: 1322px;

        @media only screen and (max-width: 991.9px) {
            // min-width: 375px;
            padding: 0px 16px 56px 16px;
            flex-direction: column;
            align-items: center;
            gap: 24px;
        }

        .banner-section{
            width: 550px;
            height: 560px;

            @media only screen and (max-width: 991.9px) {
                max-height: 270px;
                max-width: 372px;
            }

            @media only screen and (max-width: 528.9px) {
                max-height: 270px;
                max-width: 100%;
            }

            img{
                width: 100%;
                height: 100%;
            }
        }

        .cta-banner-two-content{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            
            @media only screen and (max-width: 991.9px) {
                align-self: stretch;
            }

            h2{
                color:#282829;
                /* Website/H2/Semibold */
                font-family: 'Sofia Pro Semi';
                font-size: 28px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px; /* 142.857% */
                margin-bottom: 20px;

                @media only screen and (max-width: 991.9px) {
                    color: #282829;
                    text-align: center;
                    font-family: Sofia Pro;
                    font-size: 24px;      
                    line-height: 32px; /* 133.333% */
                }
            }
            ul{
                margin-top: 12px;
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 40px;

                @media only screen and (max-width: 991.9px) {
                    margin-top: 0;
                    margin-bottom: 16px;
                    padding: 0 24px;
                }

                li{
                    color: #282829;
                    /* Website/P1/Regular */
                    font-family: Sofia Pro;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 150% */
                    margin: 8px 0;
                    display: flex;
                    align-items: flex-start;
                   
                    img{
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        margin-right: 8px;
                        margin-top: 2px;
                    }
                    p{
                        margin-bottom: 0;
                    }
                }
            }
            .cta-links{
                display: flex;
                align-items: flex-start;
                gap: 20px;
                align-self: stretch;

                @media only screen and (max-width: 991.9px) {
                    flex-direction: column;
                    row-gap: 16px;
                }
               
                .button-one{
                    text-align: center;
                    vertical-align: middle;
                    padding: 12px 24px;
                    border-radius: 48px;
                    background-color: #008CFF;
                    min-width: calc(50% - 10px);
                    color:  #FFF;

                    @media only screen and (max-width: 991.9px) {
                        width: 100%;
                    }
                    // cursor: pointer;
                    // pointer-events: none;

                    
                }
                .button-two{
                    display: flex;
                    padding: 12px 24px 12px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 48px;
                    background: #4CC764;
                    min-width: calc(50% - 10px);
                    cursor: pointer;

                    @media only screen and (max-width: 991.9px) {
                        width: 100%
                    }

                    img{
                        width: 24px;
                        height: 24px;
                    }
                    span{
                        color:  #FFF;
                        text-align: center;
                        /* Mobile/P2/Regular */
                        font-family: Sofia Pro;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px; /* 171.429% */
                    }
                }
                a:link { 
                    text-decoration: none; 
                }
                a:visited { 
                    text-decoration: none; 
                }
                a:hover { 
                    text-decoration: none; 
                }
                a:active { 
                    text-decoration: none; 
                }
            }
        }
    }
}
.review-card-wrapper{
    display: flex;
    width: calc(100% - 32px);
    padding:  32px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 2px 4px 8px 0px rgba(142, 153, 168, 0.25);
    overflow: hidden;
    margin: 0px 16px 8px 16px;
    height: 100%;

    @media only screen and (max-width: 1025.9px) {
    width: calc(100% - 32px);
        padding:  24px;
        gap:  16px;
        min-height: 380px;
    }
    // @media only screen and (max-width: 991.9px) {
    //     width: 240px;
    // }

    .review-star-section{
        display: flex;
        align-items: center;

        span{
            padding: 0 4px;
            @media only screen and (max-width: 767.9px) {
                padding: 0;
            }
        }
    }
    .review-content-wrapper{

        span{
            display: none;
            
            @media only screen and (max-width: 767.9px) {
                display: contents;
                color:  #008CFF;
                /* Website/P1/Regular UL */
                font-family: Sofia Pro;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                text-decoration-line: underline;
            }
        }
    }
    .review-content{
        color:  #282829;
        text-align: center;
        /* Website/H3/Regular */
        font-family: Sofia Pro;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 160% */
        letter-spacing: -1px;

            // @media only screen and (max-width: 767.9px) {
            //     display: -webkit-box;
            //    -webkit-box-orient: vertical;
            //     overflow: hidden;
                
            // }
        }

        p{
            margin-bottom: 0;
        }

        // .review-content.box-more{
        //     -webkit-line-clamp: 12;
        // }
        // .review-content.box-less{
        //     -webkit-line-clamp: unset;
        // }
        
       
}
.clinic-card {
    display: flex;
    flex: 1;
    gap: 24px;
    max-width: 801px;
    min-width: 720px;

    @media screen and (max-width: 768px){
        min-width: fit-content;
    }

    @media only screen and (max-width: 586px) {
        flex-direction: column;
        width: 100%;
    }

    .clinic-img-container {
        width: 336px;
        min-height: 252px;
        height: 252px;

        @media only screen and (max-width: 1055.9px) {
            width: auto;
            min-height: auto;
            height: 200px;
            width: 267px;
        }

        @media only screen and (max-width: 768px) {
            margin: 0 auto;
        }

        @media only screen and (max-width: 420px) {
            margin: 0;
            width: 100%;
        }

        img {
            height: 100%;
            width: 100%;
            border-radius: 16px;
        }
    }

    .clinic-details {
        position: relative;
        flex: 1;

        h2 {
            color: #282829;
            font-size: 28px;
            font-family: 'Sofia Pro Semi';
            font-weight: 700;
            line-height: 40px;
            margin-bottom: 20px;

            @media only screen and (max-width: 1055.9px) {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 12px;
            }
        }

        .clinic-data {
            display: flex;

            p {
                padding: 12px 0;
                margin: 0;
                color: #6E6E73;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;

                &:first-child {
                    border-right: 1px solid #E6E6E6;
                    min-width: 86px;
                    width: 86px;
                    padding-right: 16px;
                }

                &:last-child {
                    padding-left: 16px;
                }
            }
        }

        a {
            background-color: #282829;
            border-radius: 48px;
            color: #FFF;
            display: block;
            padding: 8px 24px;
            text-align: center;
            text-decoration: none;
            width: 100%;
            position: absolute;
            bottom: 0;

            @media only screen and (max-width: 768px) {
                position: static;
                margin-top: 12px;
            }

        }

        .slider-dots {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            margin-top: 24px;

            .slider-dot {
                height: 6px;
                width: 6px;
                background-color: #CCE8FF;
                border-radius: 100%;

                &.active {
                    height: 8px;
                    width: 8px;
                    background-color: #008CFF;
                }
            }
        }
    }
}
.clinic-section {
    padding: 60px 103px;
    overflow: hidden;

    @media only screen and (max-width: 1055.9px) {
        padding: 48px 16px;
    }

    .main-heading {
        color: #1E1E1E;
        font-size: 28px;
        font-family: 'Sofia Pro Semi';
        font-weight: 700;
        line-height: 40px;
        text-align: center;
        margin-bottom: 16px;

        @media only screen and (max-width: 1055.9px) {
            font-size: 24px;
            line-height: 32px;
        }

    }

    .sub-heading {
        color: #1E1E1E;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        margin-bottom: 32px;

        @media only screen and (max-width: 1055.9px) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    .location-tabs {
        display: flex;
        width: 100%;

        @media only screen and (max-width: 1055.9px) {
            display: none;
        }

        .location-tab {
            background-color: #E6F4FF;
            border-radius: 16px 16px 0 0;
            border: 1px solid #A0E3F9;
            border-bottom: 1px solid #008CFF;
            color: #008CFF;
            cursor: pointer;
            flex: 1;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            height: 52px;
            letter-spacing: -1px;
            line-height: 32px;
            padding: 10px;
            text-align: center;

            &.active {
                background-color: #FFF;
                border: 1px solid #008CFF;
                border-bottom: none;
                font-family: 'Sofia Pro Semi';
                font-weight: 700;
            }
        }
    }

    .clinic-cards-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 32px;
        border: 1px solid #008CFF;
        border-top: none;
        gap: 48px;
        flex-wrap: wrap;

        @media screen and (max-width: 1055.9px) {
            border: none;
        }

        @media only screen and (max-width: 768px) {
            display: none;
        }

    }

    .location-select-wrapper {
        display: none;

        @media only screen and (max-width: 1055.9px) {
            display: block;
        }
    }

    .slider-cards-wrapper {
        .slick-track {
            margin: 0 auto;
        }

        .slick-list {
            margin-left: -8px;

            .slick-slide {
                padding: 0 8px;

                >div:first-child {
                    display: flex;
                    justify-content: center;

                    @media screen and (min-width: 1055.9px) {
                        margin-right: 48px;
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                overflow: visible;
            }
        }

        .slick-dots {
            position: static;
            margin: 16px 0 0 0;
            transform: translate(-6px);

            li {
                background: #CCE8FF;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin: 0 12px 0 0;

                &:last-child {
                    margin-right: 0;
                }

                button {
                    width: 100%;
                    height: 100%;

                    &::before {
                        opacity: 0;
                    }
                }

                &.slick-active {
                    background: #008CFF;
                }

                @media only screen and (max-width: 1024px) {
                    width: 8px;
                    height: 8px;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            display: block;
            width: calc(100% + 8px);
        }
    }

    .location-slider-wrapper {
        padding: 32px;
        border: 1px solid #008CFF;
        border-top: none;
        box-shadow: 2px 4px 8px 0px rgba(142, 153, 168, 0.25);
        overflow: hidden;

        @media screen and (max-width: 1055.9px) {
            margin-top: 16px;
            border-radius: 16px;
            border: 1px solid #CCE8FF;
            padding: 16px;
        }
    }

}
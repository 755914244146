@import "globals/media-query.scss";
@import "variables.scss";

.collapse-wrapper {
    padding: 32px 0;
    border-bottom: 1px solid rgba(112, 112, 112, 0.25);
    position: relative;

    .collapse-header {
        cursor: pointer;
        h3 {
            font: normal normal 400 20px/32px Sofia Pro;
            letter-spacing: 0px;
            color: #282829;
            margin: 0;
            // transition: all 0.5s;
            max-width: 90%;
        }
        .plus {
            position: absolute;
            right: 7px;
            top: 39px;
            width: 18px;
            height: 18px;
            transition: all 0.5s;
            &:before,
            &:after {
                position: absolute;
                left: 8px;
                content: " ";
                height: 18px;
                width: 1.5px;
                background-color: #282829;
            }
            &:before {
                transform: rotate(90deg);
                transition: all 0.5s;
            }
            &:after {
                transform: rotate(0deg);
                transition: all 0.5s;
            }
        }
    }
    .collapse-body {
        text-align: left;
        font: normal normal normal 16px/24px Sofia Pro;
        letter-spacing: 0px;
        color: #6E6E73;
        padding: 32px 0 0;

        a{
            text-decoration: underline;
            color: #2CAAE2 !important;
        }

        li {
            margin: 5px 0;
        }

        *:last-child {
            margin-bottom: 0;
        }

        p,
        li {
            color: #6E6E73;
            strong {
                color: #6E6E73;
                font-size: 20px;
                font-weight: 500;
                line-height: 32px;
                @media screen and (max-width:767.9px) {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
        }
    }
    &.active {
        .collapse-header {
            cursor: pointer;
            h3 {
                color: #282829 !important;
                font-family: 'Sofia Pro Semi', sans-serif;
                font-weight: 700;
            }
            .plus {
                &:before {
                    transform: rotate(270deg);
                }
                &:after {
                    transform: rotate(90deg);
                }
            }
        }
    }
    &:hover {
        .collapse-header {
            h3 {
                color: #282829;
            }
            .plus {
                &:before,
                &:after {
                    background-color: #282829;
                }
            }
        }
    }
}
@include mobile {
    .collapse-wrapper {
        padding: 16px 0;
        // border-bottom: none;
        .collapse-header {
            cursor: pointer;
            h3 {
                font: normal normal normal 16px/22px Sofia Pro;
                letter-spacing: 0px;
                max-width: 90%;
            }
            .plus {
                right: 8px;
                top: 11px;
                width: 18px;
                height: 18px;
                transition: all 0.5s;
                &:before,
                &:after {
                    position: absolute;
                    left: 15px;
                    content: " ";
                    height: 18px;
                    width: 2px;

                    @media only screen and (max-width: 575.9px) {
                        height: 16px;
                        bottom: 8px;
                    }
                }
            }
        }
        .collapse-body {
            text-align: left;
            font: normal normal normal 16px/24px Sofia Pro;
            letter-spacing: 0px;
            color: #6E6E73;
            padding: 16px 0 15px;
        }
     
        &:hover {
            h3 {
                color: #282829 !important;
            }
            .collapse-header {
               
                .plus {
                    &::before,
                    &::after {
                        background-color: #282829;
                    }
                }
            }
        }
    }
}
